const numbersSections = document.querySelectorAll('section.numbers');
const observer = new IntersectionObserver(handleIntersection);

if (numbersSections.length > 0) {
    numbersSections.forEach(num => observer.observe(num));
}

function numCounter(el, maxCount, speed) {
    var initialNumber = 1;
    var timeInterval = 10;
    var totalSteps = speed / timeInterval;
    var incrementStep = (maxCount - initialNumber) / totalSteps;

    function counter() {
        if (initialNumber <= maxCount) {
            el.innerHTML = Math.floor(initialNumber);
            initialNumber += incrementStep;
        } else {
            el.innerHTML = maxCount;
            clearInterval(counterDelay);
        }
    }

    const counterDelay = setInterval(counter, timeInterval);
}

function handleIntersection(entries) {
    entries.map(entry => {
        if (entry.isIntersecting) {
            const { target } = entry;
            const counterTime = target.getAttribute('data-number-time');
            const numbers = document.querySelectorAll('.js-number-item');
            numbers.forEach(counter =>
                numCounter(counter, counter.dataset.count, counterTime)
            );
            observer.unobserve(entry.target);
        }
    });
}

import Splide from '@splidejs/splide';

const numbersSplides = document.querySelectorAll('.numbers__splide');

if (numbersSplides.length) {
    numbersSplides.forEach(function (splide) {
        new Splide(splide, {
            type: 'loop',
            autoplay: true,
            interval: 3000,
            arrows: false,
            pagination: false,
            perPage: 1,
        }).mount();
    });
}
